
import { defineComponent, ref, computed } from "vue";
import PatientModule from "../store/modules/Patient/patient";

export default defineComponent({
  name: "EmailDialog",
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  setup () {
    const isFormValid = ref(false);
    const email = ref("");
    const emailRules = ref([
      v => !!v || "The field cannot be empty. Add patient's email for notifications.",
      v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "Please add a valid email",
    ]);

    const emailFromRecord = computed(() => PatientModule.getEmail);
    email.value = emailFromRecord.value;

    const saveEmail = (): void => {
      if (email.value !== emailFromRecord.value) {
        PatientModule.setEmail(email.value);
      }
    };

    return { email, emailFromRecord, emailRules, isFormValid, saveEmail };
  },
});
